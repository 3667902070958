import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PlainFullLayout2 from "components/templates/generic/layouts/PlainFullLayout2";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import MDAvatarV2 from "components/atoms/MDAvatar";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import FormField from "components/molecules/FormField";

import logoImage from "assets/images/logo.jpg";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useSignin } from "pages/authentication/hooks/useSignin";
import { useResetPassword } from "pages/authentication/hooks/useResetPassword";

import { defaultSignedInPath } from "models/navigate";

function SignIn() {
  const { signin, error, success } = useSignin();
  const { sendPasswordResetEmail } = useResetPassword();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    "/authentication/signin"
  );
  const [errorResetPassword, setErrorResetPassword] = useState(null);
  const [successResetPassword, setSuccessResetPassword] = useState(null);
  const msgSuccessResetPassword =
    "A link to reset your password has been emailed to the address provided. Please click the link in the email to complete the password reset process.";

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || defaultSignedInPath;

  const [showDetails, setShowDetails] = useState(true);
  const handleMinimize = async () => {
    showDetails === true ? setShowDetails(false) : setShowDetails(true);
  };
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleShowResetPassword = async () => {
    showResetPassword === true
      ? setShowResetPassword(false)
      : setShowResetPassword(true);
  };

  return (
    <RedirectAuth>
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      {errorResetPassword && <ErrorAlert message={errorResetPassword} />}
      {successResetPassword && <SuccessAlert message={successResetPassword} />}
      <PlainFullLayout2 /*image={logoImage}*/>
        <MDBox
          sx={{ width: "100%", pt: 10 }}
          //mt={{ xs: -20, lg: -18 }}
          //px={1}
          //width="calc(100% - 2rem)"
          //mx="auto"
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                {/* Logo*/}
                <MDBox
                  mt={-8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDAvatarV2
                    src={logoImage}
                    alt="helms-logo"
                    bgColor="light"
                    size="xxl"
                  />
                </MDBox>

                {/* Project descriptions*/}
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Welcome to the HELMS' Healthy Lifestyle Behaviour Assessment
                    Tool
                  </MDTypography>

                  {showDetails === true ? (
                    <MDTypography
                      display="block"
                      variant="body3"
                      color="white"
                      my={1}
                      textAlign="justify"
                    >
                      Our project aims to align with a proactive approach to
                      promote health through primordial prevention of
                      cardiometabolic disease, taking the initiative and lead in
                      population health.
                    </MDTypography>
                  ) : (
                    ""
                  )}

                  <MDBox textAlign="right">
                    <MDButton
                      type="submit"
                      variant="text"
                      color="light"
                      size="small"
                      onClick={() => handleMinimize()}
                    >
                      {showDetails === true ? "hide ..." : "show ..."}
                    </MDButton>
                  </MDBox>
                </MDBox>

                {/* Prompt user to enter username & password */}
                <MDBox pt={1} pb={0} px={3}>
                  <MDTypography
                    display="block"
                    variant="body3"
                    color="dark"
                    my={1}
                  >
                    Enter your email and password to sign in.
                  </MDTypography>
                </MDBox>

                <MDBox pt={0} pb={0} px={3}>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .required("Required")
                        .email("Invalid email address"),
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      await signin(
                        values.email,
                        values.password,
                        values.remember
                      );
                      error && setSubmitting(false);
                      success && navigate(from, { replace: true });
                    }}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form>
                        <FormField
                          type="email"
                          label="Email"
                          name="email"
                          error={errors.email && touched.email}
                          success={values.email.length > 0 && !errors.email}
                        />
                        <FormField
                          type="password"
                          label="Password"
                          name="password"
                          error={errors.password && touched.password}
                          success={
                            values.password.length > 0 && !errors.password
                          }
                        />
                        <MDBox mt={-1} ml={-1}>
                          <FormField type="checkbox" label="" name="remember">
                            <MDTypography
                              variant="body3"
                              fontWeight="regular"
                              color="text"
                              sx={{
                                cursor: "pointer",
                                userSelect: "none",
                                ml: -1,
                              }}
                            >
                              &nbsp;&nbsp;Remember me
                            </MDTypography>
                          </FormField>
                        </MDBox>
                        <MDBox mt={2} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth={true}
                          >
                            sign in
                          </MDButton>
                        </MDBox>
                        {/*<MDBox mt={-1} textAlign="right">
                          <MDTypography
                            component={Link}
                            to="/authentication/resetpassword"
                            variant="caption"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            Forgot password?
                          </MDTypography>
                        </MDBox>*/}
                      </Form>
                    )}
                  </Formik>
                </MDBox>

                <MDBox textAlign="right">
                  <MDButton
                    type="submit"
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => handleShowResetPassword()}
                  >
                    Forgot password?
                  </MDButton>
                </MDBox>
                {showResetPassword && (
                  <MDBox pt={3} pb={3} px={3}>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .required("Required")
                          .email("Invalid email address"),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        await sendPasswordResetEmail(values.email, continueURL);
                        setSuccessResetPassword(msgSuccessResetPassword);
                        setErrorResetPassword(null);
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                          <FormField
                            type="email"
                            label="Email"
                            name="email"
                            errorResetPassword={errors.email && touched.email}
                            successResetPassword={
                              values.email.length > 0 && !errors.email
                            }
                          />
                          <MDBox mt={3} mb={1}>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="success"
                              fullWidth
                            >
                              Send Password Reset Email
                            </MDButton>
                          </MDBox>
                        </Form>
                      )}
                    </Formik>
                  </MDBox>
                )}

                <MDBox mt={0} mb={2} textAlign="center">
                  <MDBox>
                    <MDTypography variant="caption" color="text" textGradient>
                      Don&apos;t have an account?{" "}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={-1}>
                    <MDTypography
                      variant="caption"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Please Consult Your Administrator
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </PlainFullLayout2>
      {/*<NavCoverLayout
        image={bgImage}
        logo={
          <MDBox
            bgColor="white"
            px="0.4rem"
            py="0.4rem"
            borderRadius="xl"
            component="img"
            src={logoWideImage}
            alt="Brand"
            width="8rem"
          />
        }
        action={{
          type: "internal",
          route: "/authentication/signin",
          label: "Sign in",
        }}
        routes={mainRoutes}
      >
        <MDBox
          mt={{ xs: -20, lg: -18 }}
          px={1}
          width="calc(100% - 2rem)"
          mx="auto"
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Sign in
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="body3"
                    color="white"
                    my={1}
                  >
                    Enter your email and password to sign in.
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .required("Required")
                        .email("Invalid email address"),
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      await signin(
                        values.email,
                        values.password,
                        values.remember
                      );
                      error && setSubmitting(false);
                      success && navigate(from, { replace: true });
                    }}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form>
                        <FormField
                          type="email"
                          label="Email"
                          name="email"
                          error={errors.email && touched.email}
                          success={values.email.length > 0 && !errors.email}
                        />
                        <FormField
                          type="password"
                          label="Password"
                          name="password"
                          error={errors.password && touched.password}
                          success={
                            values.password.length > 0 && !errors.password
                          }
                        />
                        <MDBox mt={-1} ml={-1}>
                          <FormField type="checkbox" label="" name="remember">
                            <MDTypography
                              variant="body3"
                              fontWeight="regular"
                              color="text"
                              sx={{
                                cursor: "pointer",
                                userSelect: "none",
                                ml: -1,
                              }}
                            >
                              &nbsp;&nbsp;Remember me
                            </MDTypography>
                          </FormField>
                        </MDBox>
                        <MDBox mt={2} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                          >
                            sign in
                          </MDButton>
                        </MDBox>
                        <MDBox mt={-1} textAlign="right">
                          <MDTypography
                            component={Link}
                            to="/authentication/resetpassword"
                            variant="caption"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            Forgot password?
                          </MDTypography>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                  <MDBox mt={2} mb={1} textAlign="center">
                    <MDBox>
                      <MDTypography variant="caption" color="text" textGradient>
                        Don&apos;t have an account?{" "}
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={-1}>
                      <MDTypography
                        variant="caption"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Please Consult with Your Administrator
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </NavCoverLayout>*/}
    </RedirectAuth>
  );
}

export default SignIn;
